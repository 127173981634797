@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Misc.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Icons.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Fonts.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Dimensions.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Colors.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Breakpoints.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Visibility.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/MediaQueries.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Links.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/General.css";
@critical {
	a {
		color: var(--color-blue);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.LanguageMenu {
		color: var(--color-blue);

		& li {
			font-weight: var(--font-weight--bold);
		}

		& a {
			color: var(--color-black);
			font-weight: var(--font-weight);

			&:hover {
				color: var(--color-blue);
				text-decoration: none;
			}
		}
	}

	.Logo__claim {
		color: var(--color-black);
	}

	.open-menu {
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/menu.svg);
	}

	.close-menu {
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/close.svg);
	}

	.open-menu,
	.close-menu {
		text-indent: -10000px;
		width: var(--layout-spacing--uno);
		height: var(--layout-spacing--uno);
		border: none;
		background-size: cover;
		background-color: var(--color-white);
	}

	.NavMain {

		& a {
			color: var(--color-black);

			&:hover {
				color: var(--color-blue);
				text-decoration: none;
			}
		}

		& .active {
			_padding-left: var(--layout-spacing--pico);
			color: var(--color-blue);
			text-decoration: none;
			_font-weight: var(--font-weight--bold);
			_border-left: 2px solid var(--color-blue);
		}
	}

	.Breadcrumb a,
	.Footer a {
		color: var(--color-black);
		text-decoration: underline;

		&:hover {
			color: var(--color-blue);
		}
	}

	.facebook {
		text-indent: -10000px;
		width: var(--layout-spacing--uno);
		height: var(--layout-spacing--uno);
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/facebook.svg);
		background-size: cover;
		background-position: center center;

		&:hover {
			background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/facebook_hover.svg);
		}
	}

	@media (--breakpoint--uno) {

		.facebook {
			text-indent: 10000px;
		}
	}
}

body {
	font-size: var(--font-size--uno);
}

.font-size--micro {
	font-size: var(--font-size--micro);
}

.font-size--milli, small {
	font-size: var(--font-size--milli);
}

.font-size--uno {
	font-size: var(--font-size--uno);
}

.font-size--kilo {
	font-size: var(--font-size--kilo);
}

.font-size--mega {
	font-size: var(--font-size--mega);
}

.font-size--giga {
	font-size: var(--font-size--giga);
}

.font-size--tera {
	font-size: var(--font-size--tera);
}

.font-size--peta {
	font-size: var(--font-size--peta);
}

.Heading {
	font-weight: var(--font-weight--extra-bold);
	hyphens: auto;
}

.Heading--body {
	font-size: var(--heading-size--uno);
}

.Heading--small {
	font-size: var(--heading-size--kilo);
}

.Heading--medium {
	font-size: var(--heading-size--mega);
}

.Heading--secondary {
	font-size: var(--heading-size--giga);
}

.Heading--main {
	font-size: var(--heading-size--tera);
}

p, li {
	line-height: var(--line-height);
	font-weight: var(--font-weight);
}

b, strong {
	font-weight: var(--font-weight--extra-bold);
}

.text-regular {
	font-weight: var(--font-weight);
}

.text-bold {
	font-weight: var(--font-weight--bold);
}

.text-extra-bold {
	font-weight: var(--font-weight--extra-bold);
}

.text-light {
	font-weight: var(--font-weight--light);
}

.text-italic {
	font-weight: var(--font-weight);
	font-style: italic;
}

.text-italic-light {
	font-weight: var(--font-weight--light);
	font-style: italic;
}
