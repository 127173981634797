@critical {
	.NavLanguage,
	.hide-menu .NavLanguage  {
		display: inline-block;
	}

	.NavLanguage {
		list-style-type: none;
		font-size: var(--heading-size--kilo);

		& ul {
			text-transform: uppercase;
		}

		& span span,
		a span span {
			position: absolute !important;
			overflow: hidden !important;
			clip: rect(0 0 0 0) !important;
			height: 1px !important;
			width: 1px !important;
			margin: -1px !important;
			padding: 0 !important;
			border: 0 !important;
		}
	}

	@media (--breakpoint--uno) {

		.NavLanguage {
			& ul {
				text-transform: none;
			}

			& span span,
			a span span {
				position: static !important;
				overflow: visible !important;
				clip: none !important;
			}
		}
	}
}
