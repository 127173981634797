body {
	font-size: var(--font-size--uno);
}

.font-size--micro {
	font-size: var(--font-size--micro);
}

.font-size--milli, small {
	font-size: var(--font-size--milli);
}

.font-size--uno {
	font-size: var(--font-size--uno);
}

.font-size--kilo {
	font-size: var(--font-size--kilo);
}

.font-size--mega {
	font-size: var(--font-size--mega);
}

.font-size--giga {
	font-size: var(--font-size--giga);
}

.font-size--tera {
	font-size: var(--font-size--tera);
}

.font-size--peta {
	font-size: var(--font-size--peta);
}

.Heading {
	font-weight: var(--font-weight--extra-bold);
	hyphens: auto;
}

.Heading--body {
	font-size: var(--heading-size--uno);
}

.Heading--small {
	font-size: var(--heading-size--kilo);
}

.Heading--medium {
	font-size: var(--heading-size--mega);
}

.Heading--secondary {
	font-size: var(--heading-size--giga);
}

.Heading--main {
	font-size: var(--heading-size--tera);
}

p, li {
	line-height: var(--line-height);
	font-weight: var(--font-weight);
}

b, strong {
	font-weight: var(--font-weight--extra-bold);
}

.text-regular {
	font-weight: var(--font-weight);
}

.text-bold {
	font-weight: var(--font-weight--bold);
}

.text-extra-bold {
	font-weight: var(--font-weight--extra-bold);
}

.text-light {
	font-weight: var(--font-weight--light);
}

.text-italic {
	font-weight: var(--font-weight);
	font-style: italic;
}

.text-italic-light {
	font-weight: var(--font-weight--light);
	font-style: italic;
}
