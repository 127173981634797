
@critical {
	/*Regular*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-regular.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-regular.woff") format("woff");
 		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
	/*Bold*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-bold.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-bold.woff") format("woff");
 		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}
	/*Extra Bold*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-black.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-black.woff") format("woff");
 		font-weight: 800;
		font-style: normal;
		font-display: swap;
	}
	/*Light*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-light.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-light.woff") format("woff");
 		font-weight: 200;
		font-style: normal;
		font-display: swap;
	}
}
