@critical {
    .frame {
        /*max-width: var(--grid-maxwidth);*/
        margin: auto;

        & > .Heading:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .frame-space-before-extra-small {
        margin-top: var(--layout-spacing--nano);
    }

    .frame-space-before-small {
        margin-top: var(--layout-spacing--milli);
    }

    .frame-space-before-medium {
        margin-top: var(--layout-spacing--uno);
    }

    .frame-space-before-extra-large {
        margin-top: var(--layout-spacing--kilo);
    }

    .frame-space-before-extra-large {
        margin-top: var(--layout-spacing--mega);
    }

    .frame-space-after-extra-small {
        margin-bottom: var(--layout-spacing--nano);
    }

    .frame-space-after-small {
        margin-bottom: var(--layout-spacing--milli);
    }

    .frame-space-after-medium {
        margin-bottom: var(--layout-spacing--uno);
    }

    .frame-space-after-large {
        margin-bottom: var(--layout-spacing--kilo);
    }

    .frame-space-after-extra-large {
        margin-bottom: var(--layout-spacing--mega);
    }

    .video-embed {
        position: relative;
        padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
        padding-top: 0px;
        height: 0;
        overflow: hidden;

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
