@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Misc.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Icons.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Fonts.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Dimensions.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Colors.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Breakpoints.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Visibility.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/MediaQueries.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Links.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/General.css";
@critical {
    .frame {
        /*max-width: var(--grid-maxwidth);*/
        margin: auto;

        & > .Heading:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .frame-space-before-extra-small {
        margin-top: var(--layout-spacing--nano);
    }

    .frame-space-before-small {
        margin-top: var(--layout-spacing--milli);
    }

    .frame-space-before-medium {
        margin-top: var(--layout-spacing--uno);
    }

    .frame-space-before-extra-large {
        margin-top: var(--layout-spacing--kilo);
    }

    .frame-space-before-extra-large {
        margin-top: var(--layout-spacing--mega);
    }

    .frame-space-after-extra-small {
        margin-bottom: var(--layout-spacing--nano);
    }

    .frame-space-after-small {
        margin-bottom: var(--layout-spacing--milli);
    }

    .frame-space-after-medium {
        margin-bottom: var(--layout-spacing--uno);
    }

    .frame-space-after-large {
        margin-bottom: var(--layout-spacing--kilo);
    }

    .frame-space-after-extra-large {
        margin-bottom: var(--layout-spacing--mega);
    }

    .video-embed {
        position: relative;
        padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
        padding-top: 0px;
        height: 0;
        overflow: hidden;

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}


@critical {
	/*Regular*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-regular.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-regular.woff") format("woff");
 		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
	/*Bold*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-bold.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-bold.woff") format("woff");
 		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}
	/*Extra Bold*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-black.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-black.woff") format("woff");
 		font-weight: 800;
		font-style: normal;
		font-display: swap;
	}
	/*Light*/
	@font-face {
		font-family: 'Source Sans Pro';

		src:url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-light.woff2") format("woff2"),url("/typo3conf/ext/tw_infacta/Resources/Public/Fonts/sourcesanspro-light.woff") format("woff");
 		font-weight: 200;
		font-style: normal;
		font-display: swap;
	}
}

@critical {
	html {
		position: relative;
		z-index: 0;
	}

	@media (--breakpoint--uno) {
		html {
			z-index: 300;
		}
	}

	@media (--breakpoint--kilo) {
		html {
			z-index: 500;
		}
	}

	.is-hoverable {
		&:hover {
			cursor: pointer;
		}
	}
}

@critical {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html {
		/* removed subsetting from next line: var(--font-family--subset) */
		min-height: 100%;
		background-color: var(--color-grey);

		&, & button, & input, & select, & textarea {
			font: var(--font-weight) var(--font-size--uno)/var(--line-height) var(--font-family);
			letter-spacing: var(--letter-spacing);
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		&.webfonts {
			&, & button, & input, & select, & textarea {
				font-family: var(--font-family);
			}
		}
	}

	body {
		height: 100%;
		margin: 0;
		background-color: var(--color-white);
	}

	main, aside, header, footer, figure {
		display: block;
	}

	main, .Header__inner, .Breadcrumb, .Footer__Content {
		max-width: var(--content-width--uno);
		margin: 0 auto;
	}

	figure {
		margin: 0;
	}

	img,
	object,
	video,
	audio {
		width: 100%;
		height: auto;
	}

	iframe {
		max-width: 100%;
	}

	template {
		display: none;
	}

	address {
		font-style: normal;
	}

	.hide-element {
		@add-mixin hide-element;
	}

	[hidden] {
		display: none !important;
	}
}
