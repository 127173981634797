@critical {
	main {
		margin-top: var(--layout-spacing--uno);
		padding: var(--layout-spacing--uno) var(--layout-spacing--micro);
		overflow: hidden;
	}

	h1, h2, h3, h4, h5, h6 {
		clear: both;
	}

	.ce-left .image {
		width: 15%;
		float: left;
		padding: 0 var(--layout-spacing--uno) var(--layout-spacing--uno) 0;
	}

	.ce-right .image {
		width: 50%;
		float: right;
		padding: 0 0 var(--layout-spacing--micro) var(--layout-spacing--milli);
	}

	.ce-above .image {
		width: 35%;
		float: left;
		padding: 0 var(--layout-spacing--uno) var(--layout-spacing--milli) 0;
	}

	.ce-center .image,
	.ce-below .image {
		width: 100%;
		padding: var(--layout-spacing--micro) 0;
	}

	@media (--breakpoint--milli) {
		main {
			padding: var(--layout-spacing--uno);
		}
	}

	@media (--breakpoint--uno) {
		main {
			_padding-right: calc(var(--menu-width--uno) + 2 * var(--layout-spacing--uno));
			min-height: 25rem;
		}
	}
}
