@critical {
	a {
		color: var(--color-blue);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.LanguageMenu {
		color: var(--color-blue);

		& li {
			font-weight: var(--font-weight--bold);
		}

		& a {
			color: var(--color-black);
			font-weight: var(--font-weight);

			&:hover {
				color: var(--color-blue);
				text-decoration: none;
			}
		}
	}

	.Logo__claim {
		color: var(--color-black);
	}

	.open-menu {
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/menu.svg);
	}

	.close-menu {
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/close.svg);
	}

	.open-menu,
	.close-menu {
		text-indent: -10000px;
		width: var(--layout-spacing--uno);
		height: var(--layout-spacing--uno);
		border: none;
		background-size: cover;
		background-color: var(--color-white);
	}

	.NavMain {

		& a {
			color: var(--color-black);

			&:hover {
				color: var(--color-blue);
				text-decoration: none;
			}
		}

		& .active {
			_padding-left: var(--layout-spacing--pico);
			color: var(--color-blue);
			text-decoration: none;
			_font-weight: var(--font-weight--bold);
			_border-left: 2px solid var(--color-blue);
		}
	}

	.Breadcrumb a,
	.Footer a {
		color: var(--color-black);
		text-decoration: underline;

		&:hover {
			color: var(--color-blue);
		}
	}

	.facebook {
		text-indent: -10000px;
		width: var(--layout-spacing--uno);
		height: var(--layout-spacing--uno);
		background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/facebook.svg);
		background-size: cover;
		background-position: center center;

		&:hover {
			background-image: url(/typo3conf/ext/tw_infacta/Resources/Public/Icons/facebook_hover.svg);
		}
	}

	@media (--breakpoint--uno) {

		.facebook {
			text-indent: 10000px;
		}
	}
}
