.Breadcrumb {

    & ol {
        font-size: var(--font-size--milli);
        padding: 0;
        margin: 0;
    }

    & li {
        display: inline-block;

        &:after {
            content: "\00A0›\00A0";
        }

        &:last-of-type:after {
            content: " ";
        }
    }
}
