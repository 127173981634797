@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Misc.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Icons.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Fonts.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Dimensions.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Colors.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Variables/Breakpoints.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Visibility.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/MediaQueries.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Links.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/Grid.css";
@import "../../../../../../web/typo3conf/ext/tw_infacta/Resources/Private/Partials/Styles/_Config/Mixins/General.css";
.Breadcrumb {

    & ol {
        font-size: var(--font-size--milli);
        padding: 0;
        margin: 0;
    }

    & li {
        display: inline-block;

        &:after {
            content: "\00A0›\00A0";
        }

        &:last-of-type:after {
            content: " ";
        }
    }
}

@critical {
	.NavLanguage,
	.hide-menu .NavLanguage  {
		display: inline-block;
	}

	.NavLanguage {
		list-style-type: none;
		font-size: var(--heading-size--kilo);

		& ul {
			text-transform: uppercase;
		}

		& span span,
		a span span {
			position: absolute !important;
			overflow: hidden !important;
			clip: rect(0 0 0 0) !important;
			height: 1px !important;
			width: 1px !important;
			margin: -1px !important;
			padding: 0 !important;
			border: 0 !important;
		}
	}

	@media (--breakpoint--uno) {

		.NavLanguage {
			& ul {
				text-transform: none;
			}

			& span span,
			a span span {
				position: static !important;
				overflow: visible !important;
				clip: none !important;
			}
		}
	}
}

@critical {
	.NavMain__toggle {
		display: inline-block;
		width: 15%;
		padding-right: var(--layout-spacing--micro);
		vertical-align: middle;
		text-align: right;
	}

	.open-menu,
	.close-menu {
		display: inline-block;
		padding: var(--layout-spacing--micro);
		vertical-align: middle;
		overflow: hidden;
	}

	.NavMain {
		padding: var(--layout-spacing--uno) var(--layout-spacing--milli);
		font-size: var(--heading-size--kilo);
		border-top: 1px solid var(--color-black);
		border-bottom: 1px solid var(--color-black);
		background-color: var(--color-white);
		overflow-y: auto;
		z-index: 999999;
		top: 107px;

		& a {
			display: block;
			width: 100%;
			margin-bottom: var(--layout-spacing--micro);
		}

		& ol {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		& > ol > li {
			margin-bottom: var(--layout-spacing--milli);

			&:last-child {
				margin-bottom: 0;

				& a {
					margin-bottom: 0;
				}
			}
		}

		& > ol > li > a {
			padding-bottom: 0;
			margin-bottom: var(--layout-spacing--micro);
			position: relative;
			z-index: 1; 
		}

		& li {
			font-weight: var(--font-weight--bold);

			&:last-child {
				padding-bottom: 0;
			}

			& ol {
				_padding-top: var(--layout-spacing--micro);
				padding-top: 0;
			}
		}

		& ol:nth-child(2) {
			padding-left: 0;

			li {
				font-size: var(--font-size--milli);
				font-weight: var(--font-weight);
			}
		}
	}

	.hide-menu .NavMain {
		display: none;
	}

	@media (--breakpoint--milli) {
		.NavMain__toggle {
			padding-right: var(--layout-spacing--uno);
		}
	}

	@media (--breakpoint--uno) {

		.NavMain__toggle {
			display: none;
		}

		.NavMain {
			border-top: none;
			border-bottom: none;
			padding: 0;

			& > ol > li {
				margin-bottom: 0;
			}

			& > ol > li > a {
				margin-bottom: 0;
			}

			& li:last-child a {
				margin-bottom: 0;
			}

			& ol {
				padding: 0;

				&:nth-child(2) {
					padding-left: var(--layout-spacing--milli);
				}
			}
		}

		.NavMain,
		.hide-menu .NavMain {
			display: block;
			max-width: none;
			margin: 0 auto;
			overflow-y: unset;

			.content-marged & {
				position: fixed !important;
			}

			& > ol {
				max-width: var(--content-width--uno);
				margin: 0 auto;
				display: flex;
				padding: 0 var(--layout-spacing--uno) var(--layout-spacing--milli);

				& > li {
					padding-right: var(--layout-spacing--uno);
					padding-bottom: 0;
				}

				& li {
					position: relative;

					& a:focus + ol,
					&:focus-within ol,
					&:hover ol {
						display: block;
					}
				}

				& ol {
					display: none;
					position: absolute;
					top: 100%;
					left: 0;
					background-color: var(--color-white);
					padding: var(--layout-spacing--uno) var(--layout-spacing--micro) var(--layout-spacing--micro);
					width: calc(100% + var(--layout-spacing--micro) + var(--layout-spacing--micro));
					margin: 0 calc(0 - var(--layout-spacing--micro));
				}
			}
		}
	}
}

