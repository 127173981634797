@critical {
	.Footer {
		background-color: var(--color-grey);

		& ol {
			padding: 0;
			list-style-type: none;
		}

		& li {
			display: inline-block;
		}
	}

	.Footer__Content {
		padding: var(--layout-spacing--nano) var(--layout-spacing--micro) var(--layout-spacing--uno) var(--layout-spacing--micro);
	}

	address {
		vertical-align: top;

		&:first-child {
			margin-bottom: var(--layout-spacing--uno);
		}
	}

	.contenttable {
		border-collapse: collapse;

		& td {
			padding: 0;
		}
	}

	.NavMeta {
		display: block;

		& ol {
			margin-bottom: var(--layout-spacing--micro);
		}

		& li {
			&:after {
				content: "\00A0\00A0•\00A0\00A0";
			}

			&:last-of-type:after {
				content: " ";
			}
		}
	}

	.Sociallinks {
		display: inline-block;
	}

	.facebook {
		display: inline-block;
	}

	.copyright {
		display: block;
		margin-top: var(--layout-spacing--nano);
	}

	@media (--breakpoint--milli) {

		.Footer__top {
			display: inline-block;
		}

		address {
			display: inline-block;

			&:first-child {
				margin-right: var(--layout-spacing--kilo);
			}
		}

		.Footer__Content {
			padding: var(--layout-spacing--nano) var(--layout-spacing--uno) var(--layout-spacing--uno);
		}
	}

	@media (--breakpoint--uno) {

		.Footer__Content {
			overflow: hidden;
		}

		.Footer__bottom {
			display: inline-block;
			float: right;
			text-align: right;
			vertical-align: top;
		}
	}

	@media (--breakpoint--kilo) {

		address:first-child {
			margin-right: var(--layout-spacing--mega);
		}
	}
}
