@critical {
	html {
		position: relative;
		z-index: 0;
	}

	@media (--breakpoint--uno) {
		html {
			z-index: 300;
		}
	}

	@media (--breakpoint--kilo) {
		html {
			z-index: 500;
		}
	}

	.is-hoverable {
		&:hover {
			cursor: pointer;
		}
	}
}
