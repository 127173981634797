@critical {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html {
		/* removed subsetting from next line: var(--font-family--subset) */
		min-height: 100%;
		background-color: var(--color-grey);

		&, & button, & input, & select, & textarea {
			font: var(--font-weight) var(--font-size--uno)/var(--line-height) var(--font-family);
			letter-spacing: var(--letter-spacing);
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		&.webfonts {
			&, & button, & input, & select, & textarea {
				font-family: var(--font-family);
			}
		}
	}

	body {
		height: 100%;
		margin: 0;
		background-color: var(--color-white);
	}

	main, aside, header, footer, figure {
		display: block;
	}

	main, .Header__inner, .Breadcrumb, .Footer__Content {
		max-width: var(--content-width--uno);
		margin: 0 auto;
	}

	figure {
		margin: 0;
	}

	img,
	object,
	video,
	audio {
		width: 100%;
		height: auto;
	}

	iframe {
		max-width: 100%;
	}

	template {
		display: none;
	}

	address {
		font-style: normal;
	}

	.hide-element {
		@add-mixin hide-element;
	}

	[hidden] {
		display: none !important;
	}
}
