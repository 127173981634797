@critical {
	.Logo {
		display: inline-block;
		width: 60%;
		padding: var(--layout-spacing--micro) var(--layout-spacing--micro) var(--layout-spacing--milli);
		vertical-align: middle;

		& img {
			display: block;
			width: 8em;
			margin: 0 0 var(--layout-spacing--nano) -.2em;
		}
	}

	.Logo__claim {
		font-size: var(--font-size--milli);
	}

	.NavLanguage {
		display: inline-block;
		width: 25%;
		margin: 0;
		padding-right: var(--layout-spacing--micro);
		text-align: right;
		vertical-align: middle;

		& ol {
			margin: 0;
			padding: var(--layout-spacing--micro) var(--layout-spacing--uno);
		}

		& li {
			display: inline-block;

			&:after {
				content: "\00A0\00A0•\00A0\00A0";
				color: var(--color-black);
				font-weight: var(--font-weight);
			}

			&:last-of-type:after {
				content: " ";
			}
		}
	}

	.LanguageMenu {
		margin: 0;
		padding: 0;
	}

	.Header__image {
		height: 10em;
		background: transparent url(/typo3conf/ext/tw_infacta/Resources/Public/Images/header.jpg) center no-repeat;
		background-size: cover;
		overflow: hidden;
	}

	.Breadcrumb {
		padding: var(--layout-spacing--micro) var(--layout-spacing--micro) 0;
	}


	.Header__top {
		_position: sticky;
		_position: fixed;
		top: 0;
		width: 100%;
		background-color: var(--color-white);
		z-index: var(--z-overlay);

		& + .NavMain {
			_position: fixed;
			background-color: var(--color-white);
			width: 100%;
		}
	}

	.js .Header__top {
		position: fixed;

		& + .NavMain {
			position: fixed;
		}
	}

	.js .Page {
		margin-top: 96px;
	}

	/*.content-marged .Header__top {
		_position: sticky;
		_position: fixed;
		top: 0;
		width: 100%;
		background-color: var(--color-white);
		z-index: var(--z-overlay);

		& + .NavMain {
			_position: fixed;
			background-color: var(--color-white);
			width: 100%;
		}
	}*/

	@media (--breakpoint--milli) {

		.Logo {
			padding: var(--layout-spacing--uno);

			& img {
				width: 10em;
			}
		}

		.Logo__claim {
			font-size: var(--font-size--uno);
		}

		.Breadcrumb {
			padding: var(--layout-spacing--uno) var(--layout-spacing--uno) 0;
		}


		.js .Page {
			margin-top: 126px;
		}
	}

	@media (--breakpoint--uno) {

		.Header__top {
			position: fixed;

			& + .NavMain {
				position: fixed;
			}
		}

		.Page {
			margin-top: 149px;
		}

		.js .Page {
			margin-top: 149px;
		}

		.Header__inner {
			padding: var(--layout-spacing--uno);
		}

		.Logo {
			width: 75%;
			padding: 0;

			& img {
				display: inline-block;
				width: calc(10em * 1.25);
				margin: 0;
				vertical-align: top;
			}
		}

		.Logo__claim {
			display: inline-block;
			margin-top: var(--layout-spacing--uno);
			margin-left: var(--layout-spacing--milli);
			vertical-align: top;
			font-size: var(--font-size--kilo);
		}

		.NavLanguage {
			width: 25%;
			margin-top: var(--layout-spacing--uno);
			padding-right: 0;
			text-align: right;
			vertical-align: top;
			border-top: none;
		}

		.Header__image {
			height: 15em;
		}

		.Header__top + .NavMain {
			_top: 109px;
		}

		.content-marged .Header__top + .NavMain {
			position: relative;
		}
	}
}
